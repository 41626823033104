function toBoolean(value?: string) {
  switch (typeof value) {
    case 'string':
      return value.toLowerCase() === 'true'
    default:
      return false
  }
}

export const API_URL = process.env.NEXT_PUBLIC_API_SERVER_URL
export const ACCOUNT_URL = process.env.NEXT_PUBLIC_ACCOUNT_URL
export const MATH_BASECAMP_URL = process.env.NEXT_PUBLIC_MATH_BASECAMP_URL
export const AWAKENING_URL = process.env.NEXT_PUBLIC_AWAKENING_URL
export const GAMEPLAYER_URL = process.env.NEXT_PUBLIC_GAMEPLAYER_URL
export const GTM_CONFIG_URL = process.env.NEXT_PUBLIC_GTM_CONFIG_URL
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

export const GRAPHQL_URL = `${API_URL}/api2/play/graphql`

export const WEB_FLUENCY_SESSION_ENABLED = toBoolean(
  process.env.NEXT_PUBLIC_WEB_FLUENCY_SESSION_ENABLED
)
